<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{$t(`searchArea.paymentType`)}}</span> :
           </div>
           <select v-model="reqData.paystatus">
             <option value="">{{$t(`common.total`)}}</option>
             <option value="WAIT">{{$t(`searchArea.wait`)}}</option>
             <option value="COMP">{{$t(`searchArea.end`)}}</option>
           </select>
         </div>
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{$t(`searchArea.id`)}}</span> :
           </div>
           <input type="text" v-model="reqData.memId" />
         </div>
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{ $t('searchArea.date') }}</span> :
           </div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
         </div>
         <button class="btn-search" type="button" @click="pageSeach">
           <i class="fas fa-search"></i>
         </button>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-if="this.tableData.length !== 0">
              <tr v-for="(item, index) in tableData" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memid)">{{ item.memid }}</button>
                </td>
                <td>{{ item.startDate }}</td>
                <td>{{ item.endDate }}</td>
                <td>{{ $t(`gameList.${item.gameTxt}`) }}</td>
                <td>{{ item.summaryAmtPaid }}</td>
                <td>{{ item.statusTxt }}</td>
              </tr>
              <tr>
                <td colspan="5">{{ $t('searchArea.total') }}</td>
                <td>{{ totalAmt }}</td>
                <td></td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="7">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import lodash from "lodash";
import TableHead from "@/components/main/table/Head.vue";
import DateSelector from "@/components/common/DateSelector";
import { partnerDetailList } from "@/api/partner.js";
import { getCode } from "@/api/common.js";
import { replaceDateT, numberWithCommas, getDateStr } from "@/libs/utils.js";
import Pagination from "@/components/common/Pagination";
import { GAME_INFO_LIST } from "@/libs/constants";
export default {
  name: "PartnerReport2",
  components: {
    TableHead,
    Pagination,
    DateSelector
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ["index", "memId", "calcstart", "calcend", "class", "amount", "status"]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        memId: "",
        startDate: "",
        endDate: "",
        paystatus: ""
      },
      tableData: [],
      pageInfo: {},
      totalInfo: {},
      dateConfigs: {
        enableTime: false,
        dateFormat: "Y-m-d"
      },
      startDefault: "",
      endDefault: "",
      totalAmt: 0
    };
  },
  methods: {
    pageSeach() {
      this.setTableData(1);
    },
    setStartDate(date) {
      // console.log(date);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(0, 0, 0);
      } else {
        _date = date[0];
        _date.setHours(0, 0, 0);
      }
      this.startDefault = _date;
      this.reqData.startDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    setEndDate(date) {
      // console.log(date[0]);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(23, 59, 59);
      } else {
        _date = date[0];
        _date.setHours(23, 59, 59);
      }
      this.endDefault = _date;
      this.reqData.endDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    async getPartnerDetail(page) {
      const req = lodash.cloneDeep(this.reqData);
      if (page) {
        req.page = page;
      }
      const res = await partnerDetailList(req);
      // console.log(res);
      return await res.data;
    },
    gameTextConverter(groupCode, gameType) {
      const group = GAME_INFO_LIST[groupCode];
      if (group) {
        const gameTxt = group[gameType.toString()];
        if (gameTxt) {
          return gameTxt;
        }
      }
    },
    async setTableData(page) {
      const data = await this.getPartnerDetail(page);

      const memberList = data.list;
      let total = 0;
      for (const item of memberList) {
        total += Number(item.summaryAmtPaid);
        for (const key in item) {
          const child = item[key];
          if (key.indexOf("Amt") > -1 || key.indexOf("Cnt") > -1) {
            item[key] = numberWithCommas(Number(child));
          }
          if (child === "") {
            item[key] = "-";
          }
        }

        if (item.paystatus === "COMP") {
          item.statusTxt = "지급완료";
        } else if (item.paystatus === "WAIT") {
          item.statusTxt = "지급대기";
        }
        item.gameTxt = this.gameTextConverter(item.groupCode, item.gameType);
      }
      this.totalAmt = numberWithCommas(Number(total));
      // console.log(this.totalAmt)
      this.tableData = memberList;

      if (data.pageInfo) {
        this.pageInfo = data.pageInfo;
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count);
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1);
      }
    }
  },
  async created() {
    this.setStartDate();
    this.setEndDate();
    this.setTableData(1);
  }
};
</script>

<style scoped>
.pt50 {
  padding-top: 50px;
}
.mr10 {
  margin-right: 10px;
}
</style>
